function setImage(image: any) {
  const api_url = process.env.NEXT_PUBLIC_BASE_API_URL;
  try {
    if (!image) return "";

    switch (true) {
      case image.startsWith("https://"):
        return image;
      case image.startsWith("images"):
      case image.startsWith("files"):
      case image.startsWith("templates"):
        return `${api_url}/${image}`;
      default:
        return `${api_url}/uploads/${image}`;
    }
  } catch (error) {
    return "";
  }
}

export { setImage };

type ProductAttribute = {
  id: number;
  name: string;
  productId: number;
  values: { id: number; value: string; attributeId: number }[];
}[];

export const getSizeValues = (productAttributes: ProductAttribute): string[] => {
  const sizeAttribute = productAttributes.find(attr => attr.name === "Size");
  return sizeAttribute ? sizeAttribute.values.map(value => value.value) : [];
};

export const getColorValues = (productAttributes: any[]): string[] => {
  const colorAttribute = productAttributes.find(attr => attr.name === "Color");
  return colorAttribute ? colorAttribute.values.map((value: { value: any; }) => value.value) : [];
};



"use client";

import Link from "next/link";
import Prices from "./Prices";
import NcImage from "@/shared/NcImage/NcImage";
import React, { FC, useState } from "react";
import { setImage } from "@/utils";

export interface ProductCardProps {
  data?: any;
  className?: string;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
}: ProductCardProps) => {
  const [showModalQuickView, setShowModalQuickView] = useState(false);

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col pb-2 bg-white rounded-2xl divide-y
          shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(255,255,255,0.15)] 
          ${className}`}
      >
        {/* Lien vers le produit */}
        <Link href={`/product/${data?.id}`} className="absolute inset-0"></Link>

        {/* Conteneur d'image */}
        <div className="relative h-52 bg-slate-50 dark:bg-slate-300 overflow-hidden z-1 max-h-52 rounded-t-2xl">
          <Link href={`/product/${data?.id}`} className="block h-full">
            <NcImage
              fill
              alt="product"
              src={setImage(data?.images[0]?.imageUrl)}
              className="object-contain w-full h-full"
              containerClassName=""
              sizes="(max-width: 640px) 100vw, (max-width: 1200px) 50vw, 20vw"
            />
          </Link>
        </div>

        {/* Informations sur le produit */}
        <div className="space-y-4 px-3 pt-5 pb-2.5">
          <div>
            <h2 className="nc-ProductCard__title text-base font-semibold line-clamp-1 transition-colors capitalize">
              {data?.name}
            </h2>
            <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 line-clamp-2 lowercase`}>
              {data?.description}
            </p>
          </div>

          {/* Prix et disponibilité */}
          <div className="flex justify-between items-end">
            <Prices price={data?.price} />
            <div className="flex items-center mb-0.5">
              <span className="text-sm ms-1 text-slate-500 dark:text-slate-400">
                en stock
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;

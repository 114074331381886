import axios from "axios";
import axiosRetry from "axios-retry";
import { signOut } from "next-auth/react";

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 120000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Cache-Control": 604800,
  },
});

axiosRetry(axiosInstance, { retries: 2 });

// axiosInstance.interceptors.response.use(
//   (response: any) => response,
//   async (error: any) => {
//     // all the error responses
//     if (error.response) {
//       switch (error.response.status) {
//         case 400:
//           console.error(error.response.status, error.message);
//           break;

//         case 401:
//           console.error(error.response.status, error.message);
//           signOut();
//           //redirect to login
//           break;

//         case 403: // Authentification error
//           signOut();
//           console.error(error.response.status, error.message);
//           break;

//         case 404: // Not found
//           console.error(error.response.status, error.message);
//           break;

//         case 498: // Token expired
//           console.error(error.response.status, error.message);

//           break;

//         case 499: // Refresfh token expired
//           console.error(error.response.status, error.message);

//           break;

//         default:
//           console.error("error.response.message");
//       }
//     }
//     // Handle network error
//     if (!error.response?.status) {
//       console.error(error.response?.status, error.message);
//     }

//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
